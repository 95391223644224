import $ from 'jquery/dist/jquery'
import 'bootstrap/dist/js/bootstrap.bundle'
import imagesLoaded from './imagesloaded.pkgd.min'

function makeTimer() {

  //var endTime = new Date("29 April 2018 9:56:00 GMT+01:00");
  //var endTime = new Date("29 April 2022 9:56:00 GMT+01:00");
  var endTime = new Date("1 September 2021 10:17:00 GMT-04:00");
  endTime = (Date.parse(endTime) / 1000);

  var now = new Date();
  now = (Date.parse(now) / 1000);

  var timeLeft = endTime - now;

  var days = Math.floor(timeLeft / 86400);
  var hours = Math.floor((timeLeft - (days * 86400)) / 3600);
  var minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600)) / 60);
  var seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));

  if (hours < "10") { hours = "0" + hours; }
  if (minutes < "10") { minutes = "0" + minutes; }
  if (seconds < "10") { seconds = "0" + seconds; }

  $("#days").html(days + "<span>Days</span>");
  $("#hours").html(hours + "<span>Hours</span>");
  $("#minutes").html(minutes + "<span>Minutes</span>");
  $("#seconds").html(seconds + "<span>Seconds</span>");

}

setInterval(function () { makeTimer(); }, 1000);


/* SHOW HIDE MODALS */
$(function () {


  setTimeout(() => document.body.classList.add('render'), 60);

  imagesLoaded('.glitch__img', { background: true }, () => {
    document.body.classList.remove('loading');
    document.body.classList.add('imgloaded');
  });

  $('#Q3-2021-Trigger').click(function () {
    console.log('click');
    $('#Q3-2021-Modal').modal('hide');
    $('#Q4-2021-Modal').modal('show');
  });

  $('#Q4-2021-Trigger').click(function () {
    console.log('click');
    $('#Q4-2021-Modal').modal('hide');
    $('#Q1-2022-Modal').modal('show');
  });

  $('#Q1-2022-Trigger').click(function () {
    console.log('click');
    $('#Q1-2022-Modal').modal('hide');
    $('#Q2-2022-Modal').modal('show');
  });

  $('#Q2-2022-Trigger').click(function () {
    console.log('click');
    $('#Q2-2022-Modal').modal('hide');
    $('#Q3-2022-Modal').modal('show');
  });



});


/* HIGHTLIGHT MENU ITEMS */

console.log('in main.js');

// Cache selectors
var lastId,
  topMenu = $("#mainNav"),
  topMenuHeight = topMenu.outerHeight() + 1,
  // All list items
  menuItems = topMenu.find("a"),
  // Anchors corresponding to menu items
  scrollItems = menuItems.map(function () {
    var item = $($(this).attr("href"));
    if (item.length) { return item; }
  });

/*
// Bind click handler to menu items
// so we can get a fancy scroll animation
menuItems.click(function(e){
  var href = $(this).attr("href"),
      offsetTop = href === "#" ? 0 : $(href).offset().top-topMenuHeight+1;
  $('html, body').stop().animate({
      scrollTop: offsetTop
  }, 850);
  e.preventDefault();
});
*/


// Bind to scroll
$(window).scroll(function () {
  //console.log("on scroll");
  // Get container scroll position
  var fromTop = $(this).scrollTop() + topMenuHeight;

  // Get id of current scroll item
  var cur = scrollItems.map(function () {
    if ($(this).offset().top < fromTop)
      return this;
  });
  // Get the id of the current element
  cur = cur[cur.length - 1];
  var id = cur && cur.length ? cur[0].id : "";

  if (lastId !== id) {
    lastId = id;
    // Set/remove active class
    menuItems
      .parent().removeClass("active")
      .end().filter('[href="#' + id + '"]').parent().addClass("active");
  }
});

/* END: HIGHTLIGHT MENU ITEMS */


/* SMOOTH SCROLL
// Select all links with hashes
$('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
      &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1000, function() {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) { // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          };
        });
      }
    }
  });

*/

